<template>
  <div>
    <v-navigation-drawer
        v-model="$store.state.sidebar.menu"
        app
        :style="{top: $vuetify.breakpoint.mdAndDown ? $vuetify.breakpoint.smAndDown ? '56px' : '64px' : '0' }"
        :temporary="$vuetify.breakpoint.mdAndDown"
        floating
        class="light"
        max-width="260"

        mobile-breakpoint="500"
        mini-variant-width="80"
        :mini-variant.sync="mini"
        :dark="$store.getters.isDark"
    >
<!--        :permanent="$store.state.sidebar.menu"-->
<!--          $store.dispatch('toggleSidebarMenu')-->

      <v-list  v-show="!$vuetify.breakpoint.mdAndDown" dense class="v-navigation-drawer__header pt-0">
        <v-list-item class="v-list--darker">
          <v-list-item-content class=" pt-7">
            <v-img
                alt="Logo"

                contain
                :src="require('@/assets/icons/ic-sdc.svg')"
                transition="scale-transition"
                width="30"
                height="34"
                min-height="34"
                :style="{'min-height': '34px'}"
            />
            <v-list-item-title class="text-center v-list-item__title--hide-mini text-uppercase pb-2"
                               style="font-family: 'Nunito Sans'; font-style: normal; font-weight: 900; font-size: 16px;line-height: 22px; letter-spacing: 0.2em; margin-top: 16px"
            >
              SDC
            </v-list-item-title>
          </v-list-item-content>

          <v-app-bar-nav-icon style="position: absolute;  'z-index': 10002" v-show="!mini" @click.stop="$store.dispatch('toggleSidebarMini')"></v-app-bar-nav-icon>

          <v-btn
              v-show="mini"
              justi
              class="v-navigation-drawer__arrow"
              fab
              @click.stop="$store.dispatch('toggleSidebarMini')"
              small
              elevation="0"
              color="slider_main"
          >
            <v-img class="pr-2"
                   position="right+5px center"
                   contain
                   height="8"
                   width="5"
                   :src="require('@/assets/icons/ic-arrow-right-white.svg')"></v-img>
          </v-btn>
        </v-list-item>
      </v-list>


      <div class="v-navigation-drawer__content">
          <v-divider></v-divider>

          <v-list-item-group v-model="group">
            <v-list-item v-for="item in items"
                         :key="item.title"
                         :disabled="item.disabled"
                         link
                         :to="item.href">
                  <v-list-item-icon class="mt-3 mb-3">
                    <v-icon color="primary">{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title class="primary--text">{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
     </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "TheSideBar",
  data: () => ({
    group: null,
    sidebarMenu: true,
    hover: false,
    //toggleMini: false,
    // dark: true,
    items: [
      { title:"Dashboard", href: {name: 'dashboard'}, icon:"mdi-home-outline" },
      { title:"Messages", href:"/inbox", icon:"mdi-mailbox" },
      { title:"New Products", href:"/new-products", icon:"mdi-mailbox", disabled: true},
      { title:"Purchase Order", href:"/purchase", icon:"mdi-crown-outline", disabled: true },
      { title:"CAD Design Req.", href:"/cad", icon:"mdi-alarm-light-outline", disabled: true },

      { title:"Products", href:"/products", icon:"mdi-shield-account", disabled: true },
      { title:"Pricing", href:"/pricing", icon:"mdi-palette-swatch", disabled: true },
      { title:"Quality Check", href:"/quality", icon:"mdi-account-search-outline", disabled: true },
      { title:"Ticket", href:"/ticket", icon:"mdi-bus-clock", disabled: true },
      { title:"Inventory", href:"/inventory", icon:"mdi-mailbox", disabled: true },
      { title:"Shippments", href:"/shippments", icon:"mdi-mailbox", disabled: true },
      { title:"Finance", href:"/finance", icon:"mdi-mailbox", disabled: true },
      { title:"Analytics", href:"/analytics", icon:"mdi-mailbox", disabled: true },
      // { title:"Analytics2", href:"/analytics1", icon:"mdi-mailbox" },
      // { title:"Analytics3", href:"/analytics2", icon:"mdi-mailbox" },
      // { title:"Analytics10", href:"/analytics3", icon:"mdi-mailbox" },
      // { title:"Analytics11", href:"/analytics4", icon:"mdi-mailbox" },
      // { title:"Analytics12", href:"/analytics5", icon:"mdi-mailbox" },
      // { title:"Analytics13", href:"/analytics6", icon:"mdi-mailbox" },
      // { title:"Analytics14", href:"/analytics7", icon:"mdi-mailbox" },
      // { title:"Analytics15", href:"/analytics8", icon:"mdi-mailbox" },
      // { title:"Analytics16", href:"/analytics9", icon:"mdi-mailbox" },
      // { title:"Analytics17", href:"/analytics10", icon:"mdi-mailbox" },
      // { title:"Analytics18", href:"/analytics11", icon:"mdi-mailbox" },
      // { title:"Analytics19", href:"/analytics12", icon:"mdi-mailbox" },
      // { title:"Analytics20", href:"/analytics13", icon:"mdi-mailbox" },
      // { title:"Analytics21", href:"/analytics14", icon:"mdi-mailbox" },
      // { title:"Analytics22", href:"/analytics15", icon:"mdi-mailbox" }


    ],
  }),
  created() {
    const commit = this.$store.commit;
    function breaker(point) {
      switch (point) {
        case 'xs': commit('SET_MENU_STATUS', false); break;
        case 'sm': commit('SET_MENU_STATUS', false); break;
        case 'md': commit('SET_MENU_STATUS', false); break;
        case 'lg': commit('SET_MENU_STATUS', true); break;
        case 'xl': commit('SET_MENU_STATUS', true); break;
      }
    }
    breaker(this.$vuetify.breakpoint.name);
    this.$watch(()=>{
      return this.$vuetify.breakpoint.name
    }, breaker)
  },
  computed: {
    // menu() {
    //   switch (this.$vuetify.breakpoint.name) {
    //     case 'xs': this.$store.state.sidebar.menu = false;
    //     case 'sm': this.$store.state.sidebar.menu = false;
    //     case 'md': this.$store.state.sidebar.menu = false;
    //     case 'lg': this.$store.state.sidebar.menu = false;
    //     case 'xl': this.$store.state.sidebar.menu = false;
    //   }
    // dark() {
    //   return this.$store.state.themesState.dark;
    // },
    mini: {
      get() {return !this.$vuetify.breakpoint.mdAndDown && this.$store.getters.isSidebarMini; }, // this.toggleMini  // (this.$vuetify.breakpoint.mdAndDown) ||
      set(v) {
        console.log('mini ',  v);
        //return (this.$store.getters.isSidebarMini;
      } // this.toggleMini
    }
  },
}
</script>

<style lang="scss">
  .v-navigation-drawer {
    .v-list-item__title {
      align-self: center;
      font-size: 0.875rem;
    }
  }

  // Fix scroll only for content
  .v-navigation-drawer > .v-navigation-drawer__content {
    overflow: initial !important;
  }

  .v-navigation-drawer {
    overflow: initial !important;
    // right arrow button
    &__arrow {
      position: absolute !important;
      right: 0 !important;
      margin-right: -7px !important;

      height: 40px !important;
      width: 40px !important;
      overflow: initial !important;
      clip: initial ! important;
      .v-ripple__container {
        display: none;
      }
    }
    &__arrow:focus::before {
      opacity: 0!important;
    }
    &__arrow:hover::before {
       opacity: 0!important;
    }
    //fix click on the arrow
    &__border {
      display: none;
    }
    // icon padding
    &--open:not(&--mini-variant) {
      .v-list-item__icon:first-child {
        margin-right: 13px !important;
      }
    }
    &__header .v-list-item__content {
      order: 1!important;
    }

  }

  .theme--dark.v-navigation-drawer {
    background-color: $slider-body!important;
    .theme--dark.v-sheet {
      background-color: $slider-body !important;
      border-color: $slider-body !important;
      //color: rgba(0, 0, 0, 0.87) !important;
    }
    .v-list--darker{
      background: $slider-main;
    }
    .primary--text {
      color: $slider-secondary !important;
      caret-color: $slider-secondary !important;
    }
    .v-list-item--disabled {
      .primary--text {
        color: $slider-secondary--disabled !important;
      }
    }
    .v-list-item--active .v-list-item__icon .primary--text {
      color: $slider-main !important;
      caret-color: $slider-main !important;
    }
    .v-list-item--active .v-list-item__content .primary--text {
      color: $slider-secondary !important;
      caret-color: $slider-secondary !important;
    }
  }

  //.theme--light.v-navigation-drawer {
  //  .primary--text {
  //    color: #BDBDBD !important;
  //    caret-color: #BDBDBD !important;
  //  }
  //  .v-list-item--active .v-list-item__icon .primary--text {
  //    color: #7ABCF5 !important;
  //    caret-color: #7ABCF5 !important;
  //  }
  //  .v-list-item--active.v-list-item--link:before {
  //    background-color: #7ABCF5 !important;
  //  }
  //  .v-list-item--active .v-list-item__content .primary--text {
  //    color: #272C4A !important;
  //    caret-color: #272C4A !important;
  //  }
  //}

</style>
